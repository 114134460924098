.header
	background $darkRed
	@media $desktop
		height 100px
.navbar
	color $white
	margin 0 auto
	padding 0
	background $darkRed
	padding-left 15px
	padding-right 15px
	border none
	a
		&:hover
			color $white
	@media $desktop
		position static
		background none
		padding-bottom 18px
		padding-left 0
		padding-right 0
	.navbar-brand
		padding 0
		.home-link
			font-family 'Times New Roman'
			font-size 25px
			color $white
			@media $desktop
				font-size 44px
		.home-logo
			position relative
			top -3px
			width 18px
			height 18px
			@media $desktop
				width auto
				height auto
				top -6px
		p
			font-size 15px
			margin-top -6px
			word-wrap break-word
	.navbar-toggler-icon
		background-image url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	.navbar-toggler
		border-color transparent
		padding-right 0
		padding-left 0 
	.navbar-collapse
		@media $desktop
			width auto
		.navbar-nav
			margin-bottom 0.5em
			.nav-link
				color $white
				padding 0
				margin 0
			@media $desktop
				margin-bottom 0
				display flex
				margin-top 15px
				&:before
					content ''
					background url('../img/icons/home.svg') no-repeat
					width 25px
					height 25px
					margin-right 10px
					position relative
				li
					display inline-block
					margin-right 30px
					position relative
					&:after
						content ''
						width 0%
						position absolute
						left 0
						bottom -1px
						height 2px
						background $white
						transition 0.3s ease-in-out
					&:hover:after
						width 100%
					&:last-child
						margin-right 0px
				li.nav-active
					&:after
						width 100%
					
	
@media (max-width: 991px)
	.navbar-collapse
		position fixed
		top 37px
		right 0
		z-index 99
		max-width 280px
		height 100%
		overflow-y scroll
		.navbar-nav
			padding 20px
			background lighten(rgba(196, 196, 196, 1), 50%)
			margin-bottom 0 !important
		.nav-link
			position relative
			color $darkRed !important
			padding-left 10px !important
			font-size 14px
			&:last-child
				:after
					background none
		.nav-item
			position relative
			padding 7px 0 !important			
			&:after
				position absolute
				content ''
				height 1px
				background #C4C4C4
				width 100%
				bottom 0
				left 0
			&:last-child:after
				background none
	.navbar-collapse.collapsing
		height auto
		-webkit-transition right 0.3s ease
		-o-transition right 0.3s ease
		-moz-transition right 0.3s ease
		transition right 0.3s ease
		right -100%
	.navbar-collapse.show
		right 0
		-webkit-transition right 0.3s ease-in
		-o-transition right 0.3s ease-in
		-moz-transition right 0.3s ease-in
		transition right 0.3s ease-in			
	
.navbar-toggler
	display block
	position relative
	background-color transparent
	border none
	width 30px
	height 21px
	z-index 99
	top -10px
	padding 15px 5px
	@media $desktop
		display none
	.sr-only
		position: absolute
		width 1px
		height 1px
		padding 0
		margin -1px
		overflow hidden
		clip rect(0,0,0,0)
		border 0
	.icon-bar
		display block
		height 3px
		width 30px
		background-color $white
		border-radius 1px
		position absolute
		-webkit-transition all .3s ease-in-out
		-moz-transition all .3s ease-in-out
		-o-transition all .3s ease-in-out
		transition all .3s ease-in-out
		&:nth-child(2)
			top 15px
		&:nth-child(3)
			top 23px
		&:last-child
			top 31px
			

// Navbar Toggle active state
.active 
	span
		&:nth-child(2)
			-webkit-transform rotate(45deg)
			-ms-transform rotate(45deg)
			transform rotate(45deg)
			position absolute
			top 25px !important
		&:last-child
			-webkit-transform rotate(-45deg)
			-ms-transform: rotate(-45deg)
			transform rotate(-45deg)
			position absolute
			top 25px !important
		&:nth-child(3)
			opacity 0				