.article-list
	background $white
	@media $desktop
		padding-right 30px
		//padding-left 80px
.article-group
	margin-bottom 1.75em
	position relative
	.group-date
		@media $desktop
			padding-left 0
			position absolute
			font-size 13px
			left -80px
			top 0px
			width 70px
			text-align center 
	.group-date-mobile
		margin-top 0.75em
		padding-left 7px
		@media $desktop
			padding-left 0px
	hr
		margin-top 0.75em
		margin-bottom 0.75em
		border-top 1px solid rgba(0,0,0,.2)
	@media $desktop-xl
		border-left 1px solid rgba(0,0,0,.2)
		padding-left 25px
.article
	.collapse
		margin 0.75em 0
		img
			display none
		a
			display none
	&-title
		color $darkRed
		font-weight 600
		font-size 18px
		line-height 27px
		@media $desktop
			font-size 20px
		&:hover
			color $darkRed
			text-decoration underline
	p
		strong
			font-weight normal
	&-text
		font-size 14px
		img
			float left
			margin-right 1em
			max-width 200px
			height auto
	&-info
		margin 0.325em 0
		li
			display inline-block
			border-right 1px solid rgba(0,0,0,.2)
			padding-right 13px
			padding-left 10px
			line-height 16px
			font-size 12px
			&:first-child
				padding-left 0
			&:last-child
				border-right none
	&-image
		width 100%
		@media $tablet
			float left
			width 120px
.single-article
	padding-top 35px
	&-title
		@extend .article-title
		font-size 24px
		@media $desktop
			line-height 42px
			font-size 36px
		&:hover
			text-decoration none
	&-text
		p
			margin-bottom 15px
			img 
				display block
				margin-bottom 15px
				margin-right 15px
	.btn-read-all
		setTransition()
		font-size 15px
		background #453c3c
		border-radius 3px
		padding 9px
		color $white
		&:hover
			background darken(#453c3c, 20%)
.related-articles
	margin-top 100px
	margin-bottom 30px
	a
		color $darkRed
		&:hover
			color $darkRed
			text-decoration underline
	p.date 
		font-size 12px
		margin-bottom .5rem
	h3
		display block
		width 100%
		padding-left 15px
		margin-bottom 17px
	div 
		margin-bottom 20px

.fb-share-button
	a
		background #3b5998
		border-radius 3px
		font-weight 600
		padding 2px 3px
		display inline-block
		position static
	svg
		width 13px
		fill white
		vertical-align middle
		border-radius 2px
		margin-bottom 2px
	span
		color white
		font-size 12px
		padding 0 3px


.fb-share-button-lg
	a
		display inline-block
		background #3b5998
		border-radius 3px
		font-weight 600
		padding 5px 8px
		position static
		margin 0.75em 0
	svg
		width 18px
		fill white
		vertical-align middle
		border-radius 2px
		margin-bottom 2px
	span
		color white
		font-size 14px
		padding 0 3px