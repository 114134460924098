.btn-show-more
	border-radius 3px
	background $darkRed
	padding 10px 24px
	font-size 13px
	color $white
	setTransition()
	&:hover
		color $white
		background darken($darkRed, 20%)

//
// Page settings
//
.page-info
	padding-top 35px
	padding-bottom 30px
	display flex
	align-items center
	font-size 13px
	@media $desktop-xl
		//padding-left 25px
.page-settings
	margin-left auto
	display flex
	align-items center
	.dropdown
		display inline-block
	.dropdown-toggle
		background $white
		border 1px solid #453C3C
		border-radius 3px
		font-size 13px
		padding-right 30px
		padding-top 9px
		padding-bottom 10px
		position relative
		&:after
			display none
		&:before
			content ''
			background url('../img/shapes/dropdown.svg') no-repeat
			width 12px
			height 8px
			right 8px
			top 14px
			position absolute
	.dropdown-item:focus,
	.dropdown-item:active
		background $darkRed
	.posts-expand,
	.posts-collapse
		display inline-block
		width 40px
		height 40px
		align-self center
	.posts-expand
		background url('../img/buttons/expand-active.svg')
		opacity 0.6
	.posts-collapse
		background url('../img/buttons/collapse-active.svg')
			
.pagination
	.page-item
		margin 0 5px
		a
			setTransition()
			border-radius 4px
			border 1px solid #C4C4C4
			color #000
			&:focus
				box-shadow none
	.pagelinks
		li
			margin 0 5px
			display inline-block
			a
				setTransition()
				border-radius 4px
				border 1px solid #C4C4C4
				color #000
				position relative
				display block
				top -1px
				padding .45rem .75rem
				&:focus
					box-shadow none
		li.this-page
			border-radius 4px
			border 1px solid $darkRed
			color #000
			position relative
			top -1px
			display inline-block
			//padding .45rem .75rem
			a
				border none
	.prev-page,
	.next-page
		a
			background $darkRed
			color $white
			border 1px solid $darkRed
			&:hover
				background darken($darkRed, 20%)