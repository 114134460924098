.servers-list
	background $white
	padding-right 30px
	.page-info
		padding-left 0
	.server
		padding-bottom 1em
		.server-name
			color $darkRed
			font-size 20px
			font-weight 600
			&:hover
				text-decoration underline
		.row
			p,a
				font-size 13px