.vh-100
    height 100vh
    background $white
    
/*
    CLEAR
*/
.clearfix
	overflow auto
	&::after, &::before
		content ""
		clear both
		display table
		