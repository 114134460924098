.about
	background $white
	padding-top 30px
	padding-right 30px
	font-size 15px
	.heading
		font-size 30px
	.sub-heading
		font-size 24px
	.about-list
		margin-bottom 2em
		li
			list-style none
			margin-bottom 1em
			&:before
				color $darkRed  
				content ""
				background url('../img/shapes/bullet.svg') center center no-repeat 
				padding-right 2.5em
				position relative
				top 0em
	.about-text
		margin-bottom 2em
		p
			margin-bottom 1.25em