/* apply a natural box layout model to all elements */
main 
	display block

*
*:before
*:after
	-moz-box-sizing border-box
	-moz-osx-font-smoothing grayscale
	-webkit-box-sizing border-box
	-webkit-font-smoothing antialiased
	box-sizing border-box
	text-rendering optimizeLegibility
	padding 0
	margin 0


*:focus
	outline 0   
html
	background $bodyBg
body
	font-family 'Open Sans', sans-serif
	display flex
	flex-direction column
	@media $desktop
		background linear-gradient(to right, $white 50%, $gray 50%);

	
body
	display flex
	min-height 100vh
	flex-direction column
	
main
	margin-top 50px
	display flex
	flex 1
	align-items stretch
	@media $desktop
		margin-top 0
	
footer
	background $darkRed
	text-align center
	padding 20px
	.container 
		justify-content center
	a
		color $white
		&:hover
			color $white
			text-decoration underline
	p
		color $white

.container
	max-width 1150px
	margin 0 auto
	
main > .container
	display flex
	
.row 
	//height 100%

ul li 
	list-style-type none

//
// Links	
//
a,
a:hover,
a:focus
a:visited
	text-decoration none

input,
select
	box-sizing border-box
	-webkit-box-sizing border-box
	-moz-box-sizing border-box
//
// Paragraphs
//
p
	margin 0

ul
	margin-bottom 0
	
// Buttons
button,
input,
select
	outline none
	border none
	box-shadow none
	&:focus
		outline none
		border none
		box-shadow none

.btn:focus
	outline none
	border none
	box-shadow none
	
input.form-control:focus
	box-shadow none
	
img
	max-width 100%
	height auto
	
.bg-white 
	//height 104vh
	
