.sidebar
	flex-direction column
	padding 35px 15px 0 30px
.sidebar-search
	.form-group
		margin-bottom 0.6rem
	label
		font-size 15px
		color #000
	input.form-control,
		text-align left
		background $white
		font-size 15px
		border 1px solid rgba(0, 0, 0, 0.5)
		&:focus
			border 1px solid $darkRed
	select
		border 1px solid rgba(0, 0, 0, 0.5)
		&:focus
			border 1px solid $darkRed
			box-shadow none
		&:active
			box-shadow none
		// &:after
		// 	display none
		// &:before
		// 	content ''
		// 	background url('../img/shapes/dropdown.svg') no-repeat
		// 	width 12px
		// 	height 8px
		// 	right 9px
		// 	top 14px
		// 	position absolute
	option:focus
		background $darkRed
	.btn-search
		font-size 15px
		background #453C3C
		color $white
		border-radius 3px
		padding 6px 30px 7px
		setTransition()
		&:hover
			background darken(#453C3C, 25%)
.sidebar-label
	font-size 15px
.last-updated
	ul li
		font-size 12px
		a
			font-size 12px
			color $darkRed
			setTransition()
			&:hover
				color darken($darkRed, 20%)
				text-decoration underline
	ul
		a
			font-size 12px
			color $darkRed
			setTransition()
			&:hover
				color darken($darkRed, 20%)
				text-decoration underline
		span
			font-size 12px
.tags
	ul
		li
			display inline-block
			margin-right 5px
			a
				color  $darkRed
				setTransition()
				&:hover
					color darken($darkRed, 20%)
					text-decoration underline
		li.pop1
			a
				font-size 12px
		li.pop2
			a
				font-size 13px
		li.pop3
			a
				font-size 15px
		li.pop4
			a
				font-size 16px
		li.pop5
			a
				font-size 19px
.contacts
	font-size 13px
	a
		color $darkRed
		&:hover
			text-decoration underline
.pristupnost
	h6
		font-size 22px
	p
		font-size 15px
.calendar
	border-spacing 4px
	border-collapse separate
	tbody
		tr
			&:first-child
				display none
	.calendar-month
		caption-side top
		text-align center
		padding-bottom 0.2em
		a 
			color #000
			font-size 15px
		span
			color #000
	.active,
	.link
		line-height 24px
		padding 0
		background $white
		&:hover
			border-color $darkRed
		a
			display inline-block
			text-align center
			width 100%
			height 100%
			padding 3px
			border-radius 3px
			border 1px solid #C4C4C4
			color #000
	.active
		background $darkRed
		border-radius 3px
		a
			color $white
	.day
		line-height 24px
		border-radius 0
		padding 3px
		border-radius 3px
		margin-bottom 4px
		background #C4C4C4
		text-align center		
.vlist
	a
		font-size 13px
		color $darkRed
		&:hover 
			text-decoration underline
.mobile-sidebar
	background #F2F2F2
	padding 20px
	color #000
	.sidebar-label
		font-size 13px
		width 100%
	.dropdown-toggle
		font-size 12px
		padding-top 7px
		padding-bottom 7px
		&:before
			top 12px
	.dropdown-item:focus,
	.dropdown-item:active
		background $darkRed
	.form-control
		font-size 12px !important
	select.custom-select
		padding-top 3px !important
		padding-bottom 3px !important
		height auto
	.btn-search
		font-size 14px
	.page-settings
		display block
	.posts-expand,
	.posts-collapse
		width 34px
		height 34px
		background-position center center
		background-size 100%
		background-repeat no-repeat
		vertical-align middle
	.calendar
		.link
			a
				padding 3px 9px
				
			